@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

* {
  box-sizing: border-box;
  font-family: "Fredoka", sans-serif;
}

body {
  margin: 0;
  width: 100%;
  overflow-x: hidden; /* Prevents horizontal overflow */
}
